import React, { useState } from "react";
import Layout from "../layout/Layout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const initialState = {
  loading: false,
  identificacion: '',
  email: '',
  evento: 'Ventanilla',
  motivo: 'Calidad del servicio recibido',
  otroMotivo: '',
  atencionPersonalizada: 'SI',
  fullName: '',
  phone: '',
  fecha: '',
  detalleSugerencia: '',
  nombreServidor: '',
  experiencia: 'excelente',
  observacion: ''
}

const Sugerencias = () => {
  const [state, setState] = useState(initialState);


  const guardar = async () => {

    setState({ ...state, loading: true });

    /** validate  */
    // for (const key in state) {
    //   if (Object.hasOwnProperty.call(state, key)) {
    //     const element = state[key];
    //     const nombre = document.getElementsByName(key)[0];
    //     if (nombre !== undefined) {
    //       const nombrecampo = nombre.getAttribute("nombrecampo");
    //       if (key !== 'conadis_file') {
    //         if (element === undefined || element.toString().trim() === "") {
    //           const mensaje = `El campo ${nombrecampo} es obligatorio.`;
    //           setState({ ...state, loading: false })
    //           MySwal.fire({
    //             title: <strong>Alerta!</strong>,
    //             html: <i>{mensaje}</i>,
    //             icon: 'warning',
    //             timer: 3000,
    //             toast: true,
    //             position: 'bottom-end',
    //             timerProgressBar: true,
    //             showConfirmButton: false
    //           });
    //           return;
    //         }
    //       }
    //     }
    //   }
    // }
    /** validate  */

    console.error(state);
    // return;

    const url = "https://bp.bomberosmanta.gob.ec/api/opinion";
    const form = document.querySelector('form');
    const rawResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'X-requested-With': 'XMLHttpRequest',
      },
      body: new FormData(form)
    });

    if (rawResponse.ok) {
      const content = await rawResponse.json();

      MySwal.fire({
        title: <strong>Alerta!</strong>,
        text: content.message,
        icon: 'success',
      });

      form.reset();

      setTimeout(() => {
        setState(initialState)
      }, 1200);

    } else {
      const content = await rawResponse.json();
      setTimeout(() => {
        setState({ ...state, loading: false })
      }, 1200);

      if (content.status !== 500) {
        MySwal.fire({
          title: <strong>Alerta!</strong>,
          text: content.message,
          icon: 'warning',
        });
      } else {
        MySwal.fire({
          title: <strong>Alerta!</strong>,
          text: "Hubo un inconveniente al guardar, vuelva a reintentar.",
          icon: 'warning',
        });
      }

    }

  }

  return (
    <Layout>
      <h1 className="text-center text-red-700 font-bold text-xl mt-4">
        Sugerencias, denuncias y reconocimiento
      </h1>
      <div className="bg-red-800 text-white  py-4 px-4 mr-8 ml-8 font-bold  border border-2 border-black rounded-md mt-4">
        Sugerencias: Iniciativas propuestas por los ciudadanos o contibuyentes
        para mejorar el servicio de los Bomberos. Denuncias: Expresiones de
        descontento de los ciudadanos o contribuyentes hacia los servicios del
        Cuerpo de Bomberos de Manta. Reconocimientos: Expresiones de satisfacción de
        contribuyentes o ciudadanos hacia los servicios del Cuerpo de Bomberos de Manta.
      </div>
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="flex px-8">
          <div className="flex-col flex-1 mr-8">
            <div className="mb-4 flex flex-col justify-start content-center">
              <label className="w-40 text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="identificacion">
                Cédula de Identidad
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="identificacion" name="identificacion" type="text" placeholder="Nro. identificacion" value={state.identificacion}
                onChange={(event) => setState({ ...state, identificacion: event.target.value })} maxLength={13} />
            </div>

            <div className="mb-4 flex flex-col justify-start content-center">
              <label className="w-40 text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="email">
                Correo electrónico
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email" name="email" type="text" placeholder="email@email.com" value={state.email}
                onChange={(event) => setState({ ...state, email: event.target.value })} />
            </div>

            <div className="mb-4 flex flex-col justify-start content-center">
              <label className="text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="evento">
                ¿Dónde ocurrió el evento?
              </label>
              <select id="evento" value={state.evento} onChange={(event) => setState({ ...state, evento: event.target.value })}
                className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                aria-label="Default select" name="evento" nombrecampo="Nivel de instrucción">
                <option value="Ventanilla">Ventanilla</option>
                <option value="Oficina">Oficina</option>
                <option value="Departamento">Departamento</option>
                <option value="Estación de Bomberos">Estación de Bomberos</option>
                {/* <option value="Espacio público">PhD</option> */}
              </select>
            </div>

            <div className="mb-4 flex flex-col">
              <label className=" text-gray-700 text-sm font-bold mr-2 p-1">
                Motivo de sugerencia, queja o felicitación
              </label>
              <div className="px-1">
                <input
                  type="radio"
                  id="calidadServicio"
                  name="motivo"
                  value="Calidad del servicio recibido"
                  checked={state.motivo === 'Calidad del servicio recibido'}
                  onChange={(event) => setState({ ...state, motivo: event.target.value, otroMotivo: '' })}
                />
                <label htmlFor="calidadServicio" className="px-1">
                  Calidad del servicio recibido
                </label>
              </div>
              <div className="px-1">
                <input
                  type="radio"
                  id="calidadInformacion"
                  name="motivo"
                  value="Calidad de la información brindada"
                  checked={state.motivo === 'Calidad de la información brindada'}
                  onChange={(event) => setState({ ...state, motivo: event.target.value, otroMotivo: '' })}
                />
                <label htmlFor="calidadInformacion" className="px-1">
                  Calidad de la información brindada
                </label>
              </div>
              <div className="px-1">
                <input
                  type="radio"
                  id="tiempoEspera"
                  name="motivo"
                  value="Tiempo de espera y atención"
                  checked={state.motivo === 'Tiempo de espera y atención'}
                  onChange={(event) => setState({ ...state, motivo: event.target.value, otroMotivo: '' })}
                />
                <label htmlFor="tiempoEspera" className="px-1">Tiempo de espera y atención</label>
              </div>
              <div className="px-1">
                <input
                  type="radio"
                  id="solucionpeticion"
                  name="motivo"
                  value="Solución a su petición o problema."
                  checked={state.motivo === 'Solución a su petición o problema.'}
                  onChange={(event) => setState({ ...state, motivo: event.target.value, otroMotivo: '' })}
                />
                <label htmlFor="solucionpeticion" className="px-1">
                  Solución a su petición o problema.
                </label>
              </div>
              <div className="px-1">
                <div className="flex flex-col">
                  <div className="flex">
                    <input
                      type="radio"
                      id="otro"
                      name="motivo"
                      value='otro'
                      checked={state.motivo === 'otro'}
                      onChange={(event) => setState({ ...state, motivo: event.target.value })}
                    />
                    <label className="flex items-center px-1" htmlFor="otro">
                      Otro
                    </label>
                  </div>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="otroInputValue"
                    type="text"
                    maxLength={50}
                    value={state.otroMotivo}
                    onChange={(event) => setState({ ...state, otroMotivo: event.target.value })}
                    disabled={state.motivo !== 'otro'}
                  />
                </div>
              </div>
            </div>

            <div className="mb-4 ">
              <label className=" text-gray-700 text-sm font-bold mr-2">
                ¿Desea que se guarde absoluta reserva sobre esta información
                para una atención personalizada?
              </label>
              <div className="flex">
                <div className="px-8">
                  <input
                    type="radio"
                    id="atencionPersonalizada_si"
                    name="atencionPersonalizada"
                    value="SI"
                    checked={state.atencionPersonalizada === 'SI'}
                    onChange={(event) => setState({ ...state, atencionPersonalizada: 'SI' })}
                  />
                  <label htmlFor="atencionPersonalizada_si" className="px-1">SI</label>
                </div>
                <div className="px-8">
                  <input
                    type="radio"
                    id="atencionPersonalizada_no"
                    name="atencionPersonalizada"
                    value="NO"
                    checked={state.atencionPersonalizada === 'NO'}
                    onChange={(event) => setState({ ...state, atencionPersonalizada: 'NO' })}
                  />
                  <label htmlFor="atencionPersonalizada_no" className="px-1">NO</label>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-col flex-1 ml-8">
            <div className="mb-4 flex flex-col justify-start content-center">
              <label className="w-40 text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="fullName">
                Apellidos y Nombres
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="fullName" name="fullName" type="text" placeholder="Apellidos y Nombres" value={state.fullName} maxLength={100}
                onChange={(event) => setState({ ...state, fullName: event.target.value })} />
            </div>

            <div className="mb-4 flex flex-col justify-start content-center">
              <label className="w-40 text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="phone">
                Teléfono
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phone" name="phone" type="tel" placeholder="099xxxxxxx" value={state.phone} maxLength={10}
                onChange={(event) => setState({ ...state, phone: event.target.value })} />
            </div>

            <div className="mb-4 flex flex-col justify-start content-center">
              <label className="w-40 text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="fecha">
                Fecha evento
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="fecha" name="fecha" type="date" value={state.fecha}
                onChange={(event) => setState({ ...state, fecha: event.target.value })}
              />
            </div>

            <div className="mb-4 flex flex-col content-center">
              <label className="text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="detalleSugerencia">
                Detalle de la sugerencia, queja o felicitación
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="detalleSugerencia" name="detalleSugerencia" type="text" placeholder="Detalle de la sugerencia, queja o felicitación" rows={5} maxLength={450}
                value={state.detalleSugerencia} onChange={(event) => setState({ ...state, detalleSugerencia: event.target.value })}></textarea>
            </div>

            <div className="mb-4 flex flex-col content-center">
              <label className=" text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="nombreServidor">
                Nombre del servidor(a) y/o número de ventanilla
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="nombreServidor" name="nombreServidor" type="text" placeholder="Nombre del servidor(a) y/o número de ventanilla" maxLength={50}
                value={state.nombreServidor} onChange={(event) => setState({ ...state, nombreServidor: event.target.value })}
              />
            </div>

            <div className="mb-4 ">
              <label className=" text-gray-700 text-sm font-bold mr-2">
                ¿Cuál ha sido mi experiencia como usuario del servicio de los
                bomberos?
              </label>
              <div className="flex justify-between px-2">
                <div className="">
                  <input
                    type="radio"
                    id="experiencia_excelente"
                    name="experiencia"
                    value="excelente"
                    checked={state.experiencia === 'excelente'}
                    onChange={(event) => setState({ ...state, experiencia: event.target.value })}
                  />
                  <label htmlFor="experiencia_excelente" className="px-1">Excelente</label>
                </div>
                <div className="">
                  <input
                    type="radio"
                    id="experiencia_buena"
                    name="experiencia"
                    value="buena"
                    checked={state.experiencia === 'buena'}
                    onChange={(event) => setState({ ...state, experiencia: event.target.value })}
                  />
                  <label htmlFor="experiencia_buena" className="px-1">Buena</label>
                </div>
                <div className="">
                  <input
                    type="radio"
                    id="experiencia_regular"
                    name="experiencia"
                    value="regular"
                    checked={state.experiencia === 'regular'}
                    onChange={(event) => setState({ ...state, experiencia: event.target.value })}
                  />
                  <label htmlFor="experiencia_regular" className="px-1">Regular </label>
                </div>
                <div className="">
                  <input
                    type="radio"
                    id="experiencia_mala"
                    name="experiencia"
                    value="mala"
                    checked={state.experiencia === 'mala'}
                    onChange={(event) => setState({ ...state, experiencia: event.target.value })}
                  />
                  <label htmlFor="experiencia_mala" className="px-1">Mala </label>
                </div>
              </div>
            </div>

            <div className="mb-4 flex flex-col">
              <label className=" text-gray-700 text-sm font-bold mr-2 p-1" htmlFor="observacion">
                Mi observación o recomendación a los servicios recibidos es:
              </label>
              <textarea
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="observacion" name="observacion" type="text" placeholder="Detalle observaciones o recomendaciones de acuerdo a los servicios que recibio" rows={5}
                value={state.observacion} onChange={(event) => setState({ ...state, observacion: event.target.value })}
              ></textarea>
            </div>

            <div className="flex items-center justify-end">
              <div className="flex items-center justify-end pb-10 mb-5">
                {state.loading === true ?
                  <button className="bg-blue-300 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" disabled="disable">
                    <span>Guardando...!</span>
                  </button>
                  :
                  <button className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button" onClick={guardar}>
                    <span>Registrar</span>
                  </button>
                }
              </div>
              {/* <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button">
                Registrar
              </button> */}
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default Sugerencias;
